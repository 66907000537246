<template>
    <profile-layout>
        <template #header>
            <div
                class="arrowWrap"
                @click="toProfile"
            >
                <arrow />
            </div>
            Change phone
        </template>
        <template #content>
            <div class="input-field">
                <phone-number />
            </div>
            <password-modal
                :name="'password-test-phone'"
                :title="'Change phone'"
            />
        </template>
        <template #footer>
            <custom-button
                default
                class="button btn-base_colored"
                :disabled="!verified"
                :type="'submit'"
                tabindex="3"
                width="100%"
                @on-btn-click="validationData"
            >
                Submit
            </custom-button>
        </template>
    </profile-layout>
</template>

<script>
import {
    ACTION_GET_CURRENT_USER
} from '@/store/modules/auth/action-types'
import { createNamespacedHelpers, mapState } from 'vuex'

import { mapPhoneFields } from '@/store/modules/phone'

import { changPhone } from '@/services/profileService';
import ProfileLayout from '@/layouts/ProfileLayout'
import PasswordModal from '@/components/modals/PasswordModal'
import Arrow from '@/icons/Arrow'

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('auth')

export default {
    name: 'ChangePhone',
    components: { ProfileLayout, Arrow, PasswordModal },
    computed: {
        ...mapState('phone', {
            verified: 'verified'
        }),
        ...mapPhoneFields([
            'form_data.country',
            'form_data.phone_number'
        ])
    },
    activated() {
        document.querySelector('input')?.focus()
        this.$bus.$on('confirm', this.submit)
    },
    deactivated() {
        this.$bus.$off('confirm', this.submit)
    },
    methods: {
        ...mapAdminActions([
            ACTION_GET_CURRENT_USER
        ]),
        toProfile() {
            this.$router.push({ name: 'user-profile' })
        },
        validationData() {
            if (!this.verified) {
                this.$bus.$emit('showSnackBar', 'You must verify you phone before changing', 'error')
                return
            }
            this.$bus.$emit('openModal', 'password-test-phone')
        },
        submit(name) {
            if (name !== 'password-test-phone') { return }
            changPhone(this.phone_number, this.country.phone_code).then(async () => {
                await this[ACTION_GET_CURRENT_USER]()
                this.$bus.$emit('showSnackBar', 'Your phone was changed', 'success')
                this.toProfile()
            }).catch((e) => this.$bus.$emit('showSnackBar', e.response?.data?.message || 'Invalid data', 'error')).finally(() => {
                this.$store.commit('phone/SET_DEFAULT')
            })
        }
    }
}
</script>

<style scoped>
.input-field {
    margin-bottom: 35px;
}
</style>

<style>
 .profile-wrap fieldset {
     padding: 0!important;
 }
</style>
